// stylelint-disable at-rule-no-unknown
// ------------------------------------- //
// mixins for media queries

@mixin screen-vlg {
    @media (max-width: $media-vlg) {
        @content;
    }
}

@mixin screen-lg {
    @media (max-width: $media-lg) {
        @content;
    }
}

@mixin screen-md {
    @media (max-width: $media-md) {
        @content;
    }
}

@mixin screen-sm {
    @media (max-width: $media-sm) {
        @content;
    }
}

@mixin screen-xs {
    @media (max-width: $media-xs) {
        @content;
    }
}

// ------------------------------------- //
// mixins for components

// @mixin inputGroupLabel {
//     display: flex;
//     flex-direction: column;
//
//     &-label {
//         font-size: 16px;
//         color: $color-darkgray;
//         margin-bottom: $spacing-half;
//
//         @include screen-md {
//             font-size: 14px;
//         }
//
//         @content;
//     }
// }

@mixin horizontal-scroller {
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.3);
        border-radius: 20px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar {
        height: 10px;
        width: 5px;
        background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $color-brand-blue;
    }
}

@mixin hover-effect {
    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
}

@mixin submit-btn {
    border-radius: 4px;
    box-sizing: border-box;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 17px;
    letter-spacing: 0.5px;
    border: none;
    padding: 15px 15px 14px;
    text-align: center;

    @include hover-effect;
}

@mixin modal-back-btn {
    background: url(/assets/images/general/left-arrow-long.svg);
    width: 24px;
    height: 24px;

    @include hover-effect;
}

@mixin label {
    line-height: 17px;
    font-weight: 500;
    letter-spacing: 0.5px;
    font-size: 0.875rem;
}

@mixin input {
    display: block;
    background: $color-white;
    border: $input-border;
    box-sizing: border-box;
    box-shadow: $input-box-shadow;
    border-radius: 4px;
    padding: 8px 22px 6px;
    font-weight: 500;
    font-size: 1rem;
    line-height: 33px;
    letter-spacing: 0.5px;

    &::placeholder {
        color: $color-gray-blue;
    }
}

@mixin textarea {
    @include input;

    padding: 11px 12px;
    resize: none;
    border-radius: 3px;
    font-family: $font-family-body;
    font-size: 0.875rem;
    line-height: 33px;
    letter-spacing: 0.5px;
}

@mixin select {
    display: block;
    background: url(/assets/images/general/chevron.svg) no-repeat 95%;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 17px;
    letter-spacing: 0.5px;
    padding: 16px 22px 14px;
    box-shadow: $input-box-shadow;
    box-sizing: border-box;
    border: $input-border;
    border-radius: 4px;
    background-color: $color-white;
    background-position-x: calc(100% - 19px);
    background-size: 12px;
    -moz-appearance: none;
    -webkit-appearance: none;
    font-family: $font-family-body;

    &--null {
        color: $color-gray-blue;
    }

    &:focus {
        outline: none;
    }
}

@mixin option {
    color: $color-dark-blue;
}

@mixin pulsing($name, $percent, $shadow-width, $color) {
    @keyframes #{$name} {
        0% {
            box-shadow: 0 0 0 0 $color;
        }

        #{$percent} {
            box-shadow: 0 0 0 $shadow-width $color;
        }

        100% {
            box-shadow: 0 0 0 0 $color;
        }
    }
}
