@import 'styles/_variables';
@import 'styles/_mixins';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $color-dark-blue;
    z-index: 2;

    &__backdrop {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        z-index: $zIndexBackDrop;
        transition: opacity 150ms linear;
        background-color: $color-black;
        animation-name: FadeIn;
        animation-iteration-count: 1;
        animation-duration: 150ms;
        animation-timing-function: linear;
        animation-fill-mode: forwards;

        @keyframes FadeIn {
            from { opacity: 0; }
            to { opacity: 0.5; }
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
    }

    &__wrapper {
        background-color: $color-white;
        border-radius: 12px;
        padding: 13px 40px;
        position: relative;
        width: auto;
        z-index: $zIndexModal;
        overflow-y: auto;

        &--compact {
            padding: 5px 42px;
        }
    }

    @include horizontal-scroller;

    &__header {
        position: relative;
        margin: 17px 0;

        &--compact {
            margin: 8px 0;
        }
    }

    &__title {
        font-size: 1.125rem;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
    }

    &__close-button {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        background: url(/assets/images/general/cross.svg) no-repeat;
        height: 14px;
        width: 14px;
        border: none;

        @include hover-effect;

        &:focus {
            outline: none;
        }
    }
}
