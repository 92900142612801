@import 'styles/_variables';
@import 'styles/_mixins';

.demo-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    align-items: center;
    min-width: 850px;
    height: 46px;
    background-color: $color-dark-blue;

    &__live {
        width: 110px;
        background-color: $color-green-soft;
        color: $color-black;
        border-radius: 4px;
        font-size: 0.875rem;
        font-weight: 400;
        height: 29px;

        @include hover-effect;
    }

    &__resume {
        min-width: 190px;
        background-color: $color-green-soft;
        color: $color-black;
        border-radius: 4px;
        font-size: 0.875rem;
        font-weight: 400;
        height: 29px;

        @include hover-effect;
    }

    &__pending {
        width: 200px;
        background-color: $border-color;
        color: $color-black;
        border-radius: 4px;
        font-size: 0.875rem;
        font-weight: 400;
        height: 29px;
        cursor: auto !important;
    }

    &__approved {
        width: 200px;
        background-color: $color-green-soft;
        color: $color-black;
        border-radius: 4px;
        font-size: 0.875rem;
        font-weight: 400;
        height: 29px;
        cursor: auto !important;
    }

    &__text {
        color: $color-white;
        font-size: 0.875rem;
        font-weight: 400;
        margin-right: 29px;
    }

    &__hamburger-button {
        display: none;
        margin-left: 16px;
        cursor: pointer;
    }

    @include screen-lg {
        padding: 0 80px;
    }
}
