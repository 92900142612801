@import 'styles/_variables';
@import 'styles/_mixins';

.modal-cancel-order-screen {
    .cancel-order {
        padding: 0 40px 0 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 33px;
            margin: 52px 0 14px;
        }

        &__message {
            width: 300px;
            font-size: 1rem;
            line-height: 28px;
            letter-spacing: 0.5px;
            color: $color-gray-blue;
            text-align: center;
            margin-top: 20px;
            margin-bottom: 20px;
        }


        &__submit-button {
            @include submit-btn;

            width: 210px;
            color: $color-white;
            margin-top: 10px;

            @include hover-effect;

            &--red {
                background: $color-red;
            }

            &--white {
                background: $color-white;
                border: 1px solid $border-color;
                color: $color-gray-blue;
            }
        }
    }
}
