// see https://create-react-app.dev/docs/adding-css-reset/
@import-normalize;

@import '_variables';
@import '_mixins';
@import '_normalize';
@import '_global';
@import '_components';

@font-face {
    font-family: sfPro;
    src: url('../assets/fonts/SF-Pro-Text-Light.otf') format('opentype');
    font-weight: 100;
}

@font-face {
    font-family: sfPro;
    src: url('../assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
    font-weight: 400;
}

@font-face {
    font-family: sfPro;
    src: url('../assets/fonts/SF-Pro-Text-Medium.otf') format('opentype');
    font-weight: 500;
}

@font-face {
    font-family: sfPro;
    src: url('../assets/fonts/SF-Pro-Text-Semibold.otf') format('opentype');
    font-weight: 600;
}

@font-face {
    font-family: sfPro;
    src: url('../assets/fonts/SF-Pro-Display-Bold.otf') format('opentype');
    font-weight: 700;
}

@font-face {
    font-family: Roboto;
    src: url('../assets/fonts/Roboto-Thin.ttf') format('truetype');
    font-weight: 100;
}

@font-face {
    font-family: Roboto;
    src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: Roboto;
    src: url('../assets/fonts/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
}
