.AppLoading {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 100px;

    &__loader {
        width: 50px;
        height: 110px;
    }
}
