.pt-tooltip {
    background-color: $tooltip-color;
    opacity: 1;
    font-family: $font-family-roboto;
    font-weight: 400;
    font-size: 0.8125rem;
    line-height: 17px;
    padding: 4px;
    letter-spacing: 0.5px;

    .tippy-content {
        padding: 4px;
    }

    &__status-wrapper {
        display: flex;
        align-items: center;
        font-size: 1rem;
        color: $color-white;
        letter-spacing: 0.5px;
        font-weight: 500;
    }

    &__status-container {
        width: 24px;
        height: 24px;
        background-color: $color-white;
        border-radius: 4px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


.orders__order-status {
    width: 13px;
    height: 13px;
    border-radius: 2px;
    border: 1.5px solid $border-color-light;
    background-color: $color-white;
    box-shadow: 0 4px 4px $box-shadow-color-darker;

    &--inline {
        display: inline-block;
    }

    &--no-orders {
        background-color: $color-white;
    }

    &--order-no-negotiation {
        background-color: $bg-color-button;
    }

    &--order-under-negotiation {
        background-color: $color-negotiation;
    }

    &--order-negotiation-done {
        background-color: $color-green;
    }

    &--order-withdrawn {
        background-color: $color-red;
    }

    &--outline {
        border: 2px solid $box-shadow-color-darker;
    }
}

// NO BEM RULE
.image-cropper {
    width: 28px;
    height: 28px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 28px;
}

.image-cropper img {
    height: 100%;
}

.ant-table .ant-table-selection-col {
    width: 52px;
}

.ant-table-wrapper {
    .ant-table-content {
        box-shadow: 0 0 8px $box-shadow-color;
    }

    .ant-table-thead .ant-table-cell {
        background-color: $color-gray-ultra-soft;
    }

    .ant-table-tbody .ant-table-cell {
        padding: 9px 16px;
    }

    .ant-table-cell .usage-value {
        color: $color-green;
        font-size: 0.625rem;
        display: flex;
        height: 14px;

        img {
            width: 10px;
            margin-right: 2px;
        }
    }

    .ant-table-row-selected td {
        background-color: $bg-color-body !important;
    }

    .selector-component__disable.ant-table-row-selected td {
        background-color: $color-dark-gray !important;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        background-color: $color-low-blue;
        border-color: $color-low-blue;
    }
}

.ant-input-search {
    .ant-input-search-button {
        background-color: $color-brand-blue;
        border: none;
    }
}

.global-wrapper {
    margin: 0 70px;
}

.usage-value-alert {
    color: $color-red !important;
}

.usage-value-warning {
    color: $color-negotiation !important;
}

.ant-select-dropdown {
    pointer-events: all !important;
}

div.ant-picker {
    border-radius: 4px;
    border: 1px solid $border-color;
}

.ant-picker-now-btn,
.ant-picker-today-btn {
    color: $color-gray-blue;
    cursor: pointer;
}

.ant-picker.ant-picker-range {
    .ant-picker-clear {
        right: -8px;
    }

    .ant-picker-active-bar {
        margin-left: 0;
    }

    .ant-picker-input {
        input {
            font-weight: bold;
            text-align: center;
        }

        input::-ms-input-placeholder {
            /* Microsoft Edge */
            color: $color-dark-blue;
            font-weight: bold;
        }

        input::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $color-dark-blue;
            opacity: 1; /* Firefox */
            font-weight: bold;
        }

        input:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $color-dark-blue;
            font-weight: bold;
        }
    }
}
