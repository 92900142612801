@import 'styles/_variables';
@import 'styles/_mixins';

.switch {
    &__wrapper {
        width: 34px;
        height: 14px;
        border-radius: 7px;
        background-color: $color-brand-blue;
        position: relative;

        &--enabled {
            @include hover-effect;
        }

        &--disabled {
            background: $border-color;
        }

        &--off-gray {
            background: $border-color;
        }
    }

    &__toggler {
        position: absolute;
        top: calc(50% - 20px / 2);
        width: 20px;
        height: 20px;
        border-radius: 10px;
        box-shadow: $switch-box-shadow;
        transform: matrix(-1, 0, 0, 1, 0, 0);
        background: $color-white;

        &--on {
            right: 0;
        }

        &--off {
            left: 0;
        }

        &--disabled {
            background: $border-color;
        }
    }
}
