@import 'styles/_variables';
@import 'styles/_mixins';

.cancel-menu {
    position: absolute;
    top: 49px;
    left: 0;
    width: 100%;
    height: 100%;
    color: $color-dark-blue;
    z-index: $zIndexBackDrop;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        position: relative;
        margin-right: 20px;
    }

    &__pointer {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 16px solid $color-white;
        margin-top: -20px;
        z-index: $zIndexModal;
        margin-right: 28px;
    }

    &__wrapper {
        background-color: $color-white;
        border-radius: 7px;
        position: relative;
        width: 250px;
        z-index: $zIndexModal;
        display: flex;
        flex-direction: column;
    }

    &__header {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 10px 0 40px 0;
    }

    &__title {
        font-size: 1rem;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
        color: $color-dark-blue;
    }

    &__close-button {
        position: absolute;
        left: 0;
        height: 22px;
        width: 22px;
        border: none;
        transform: scale(1.2);

        @include hover-effect;

        &:focus {
            outline: none;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__row {
        margin: 15px;
        color: $color-red;
        font-weight: 500;
        font-size: 0.875rem;
        letter-spacing: 0.5px;

        @include hover-effect;
    }


    &__separator {
        background-color: $bg-color-body;
        width: 100%;
        height: 1px;
    }


    &__selector-row {
        margin: 15px;
        display: flex;
        border: 1px solid $border-color;
        border-radius: 4px;
        justify-content: space-between;
    }

    &__switch-button {
        font-size: 0.75rem;
        letter-spacing: 0.5px;
        padding: 4px 8px;
        color: $color-dark-blue;
        font-weight: 500;
        border-radius: 4px;
        flex: 2;
        align-items: center;

        @include hover-effect;

        &--active {
            color: $color-white;
            background-color: $color-brand-blue;
        }

        &--second {
            flex: 3;
        }
    }

    @include screen-lg {
        left: 261px;

        &__container {
            margin-top: -50px;
            margin-right: 0;
        }

        &__pointer {
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 16px solid $color-white;
            margin-top: -20px;
            z-index: 1600;
            position: relative;
            left: -217px;
            bottom: -28px;
        }
    }
}
