@import 'styles/_variables';
@import 'styles/_mixins';

.order-menu {
    position: absolute;
    top: 49px;
    left: 0;
    width: 100%;
    height: 100%;
    color: $color-dark-blue;
    z-index: $zIndexBackDrop;

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        position: relative;
        margin-right: 30px;
        margin-top: 0;
    }

    &__pointer {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 16px solid $color-white;
        margin-top: -20px;
        z-index: $zIndexModal;
        margin-right: 23px;
    }

    &__wrapper {
        background-color: $color-white;
        border-radius: 7px;
        position: relative;
        width: 164px;
        z-index: $zIndexModal;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
    }

    &__header {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 10px 0 40px 0;
    }

    &__title {
        font-size: 1rem;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
        color: $color-dark-blue;
    }

    &__close-button {
        position: absolute;
        left: 0;
        height: 22px;
        width: 22px;
        border: none;
        transform: scale(1.2);

        @include hover-effect;

        &:focus {
            outline: none;
        }
    }

    &__body {
        display: grid;
        gap: 15px;
    }

    &__row {
        padding: 0 20px;

        @include hover-effect;
    }

    &__separator {
        width: 100%;
        height: 1px;
        background-color: $border-color;
    }

    @include screen-lg {
        left: 205px;

        &__container {
            margin-top: -50px;
        }

        &__pointer {
            width: 0;
            height: 0;
            border-top: 12px solid transparent;
            border-bottom: 12px solid transparent;
            border-right: 16px solid $color-white;
            margin-top: -20px;
            z-index: 1600;
            position: relative;
            left: -137px;
            bottom: -28px;
        }
    }
}
