@import 'styles/_variables';
@import 'styles/_mixins';

.brokerage-select {
    &--readonly {
        flex: 1;
    }


    &__wrapper {
        &--error {
            border: 1px solid $color-red;
            padding: 8px 10px;
            margin-bottom: 8px;
        }
    }

    &__btn-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
    }

    &__btn {
        padding: 0 7px 0;
        background-color: $color-white;
        margin-right: 12px;
        box-sizing: border-box;
        border-radius: 5px;
        line-height: 32px;
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 0.875rem;
        color: $bg-color-button;
        border: 1px solid $bg-color-button;
        margin-bottom: 3px;
        margin-top: 3px;

        &--selected {
            background-color: $bg-color-button;
            color: $color-white;
        }


        &:focus {
            outline: none;
        }

        &--active {
            @include hover-effect;
        }
    }

    &__show-qty {
        flex-direction: column;
        flex: 1;
    }

    &__broker-box {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }

    &__qty {
        font-size: 0.875rem;
        font-weight: 400;
        letter-spacing: 0.5px;
    }

    &__select-all {
        display: flex;
        align-items: center;
    }

    &__error-text {
        color: $color-red;
        line-height: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 0.75rem;
    }

    &__no-brokerages-text {
        line-height: 14px;
        font-weight: 400;
        letter-spacing: 0.5px;
        font-size: 0.75rem;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .select-all-brokerage {
        &__box {
            border: $checkbox-border;
        }

        &__label {
            color: $checkbox-color;
            line-height: 14.32px;
            size: 0.25rem;
        }
    }
}
