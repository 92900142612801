@import 'styles/_variables';
@import 'styles/_mixins';

.checkBox {
    margin: 20px 0;
    width: auto;
    display: inline-block;

    @include screen-md {
        margin: 10px 0;
    }

    &__label-tag {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-top: 0;
        font-weight: 500;
        font-size: 0.875rem;
        color: $color-gray-blue;
    }

    &__label-tag-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    &__label-tag-mark {
        position: absolute;
        top: 0;
        left: 0;
        height: 18px;
        width: 18px;
        background: $color-white;
        border: $checkbox-border;
        box-sizing: border-box;
        border-radius: 4px;
    }

    &__label {
        font-weight: 500;
        font-size: 0.875rem;
        line-height: 17px;
        letter-spacing: 0.5008px;
        color: $color-gray-blue;
        margin-bottom: 10px;
        display: block;
    }
}

.checkBox__label-tag-input:checked ~ .checkBox__label-tag-mark {
    background-color: $checkbox-bg;
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgOSA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTcuODE1MzkgMEwzLjA1ODc4IDQuNjc1OEwxLjE3OTg2IDIuODMzMTlMMCAzLjk5MTk2TDMuMDU5NTcgN0w5IDEuMTU4MDJMNy44MTUzOSAwWiIgZmlsbD0iI0ZFRkVGRSIvPgo8L3N2Zz4K');
    border: 1px solid rgba(0, 0, 0, 0.05);
    box-sizing: border-box;
    box-shadow: inset -1px 1px 0 rgba(255, 255, 255, 0.0001);
    border-radius: 4px;
    background-repeat: no-repeat;
    background-position: center;
}

.disabled-check-box {
    background-color: $color-lightgray !important;
}
