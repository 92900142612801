@import 'styles/_variables';
@import 'styles/_mixins';

.modal-create-order-screen {
    .create-order {
        &__field-select {
            @include select;

            width: 475px;
            margin-bottom: 15px;

            &--company {
                margin-bottom: 0;
            }

            &--inline {
                width: 340px;
            }
        }

        &__field-select-option {
            @include option;
        }

        &__field-group {
            display: flex;
            justify-content: space-between;
        }

        &__field {
            display: flex;
            align-items: flex-start;
            margin-bottom: 10px;
            flex-direction: column;

            &--inline {
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
            }
        }

        &__field-label {
            @include label;

            margin-bottom: 13px;
            width: 60px;

            &--wide {
                width: auto;
                display: inline-block;
            }

            &--inline {
                width: auto;
                margin-bottom: 0;
                margin-right: 15px;
            }
        }

        &__field-input {
            @include input;

            width: 230px;
        }

        &__company-name {
            background: $bg-color-body;
            padding: 11px 12px 10px 14px;
            display: flex;
            font-weight: 500;
            font-size: 1rem;
            line-height: 33px;
            letter-spacing: 0.5px;
            border: 0.5px solid $border-color;
            margin-top: 11px;
            border-radius: 2px;
            margin-bottom: 12px;
            justify-content: space-between;
        }

        &__name-hidden {
            display: inline-block;
            margin-right: 10px;
        }

        &__name-visible {
            display: inline-block;
            margin-left: 10px;
        }

        &__toggles {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__type-row {
            background: $bg-color-body;
            padding: 11px 12px 10px 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-size: 1rem;
            line-height: 33px;
            letter-spacing: 0.5px;
            border: 0.5px solid $border-color;
            margin-top: 0;
            border-radius: 2px;
            margin-bottom: 10px;
            width: 230px;
        }

        &__qty-type {
            background: $bg-color-body;
            padding: 11px 12px 10px 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            font-size: 1rem;
            line-height: 33px;
            letter-spacing: 0.5px;
            border: 0.5px solid $border-color;
            margin-top: 0;
            border-radius: 2px;
            margin-bottom: 10px;

            &--disable {
                opacity: 0.4;
            }
        }

        &__type-split {
            display: inline-block;
        }

        &__split-volume {
            margin-left: 10px;

            &--invalid {
                color: $color-red;
            }
        }

        &__type-total {
            display: inline-block;
        }

        &__brokerages-list {
            width: 475px;
            margin-bottom: 10px;
        }

        &__condition-box {
            background: $bg-color-body;
            padding: 22px 21px 22px 25px;
            border-radius: 3px;
            margin-bottom: 12px;
            border: 1px solid $border-color;
        }

        &__commision-box {
            width: 475px;
            background: $bg-color-body;
            padding: 10px;
            border-radius: 3px;
            margin-bottom: 18px;
            color: $color-dark-blue;
            display: flex;
            align-items: flex-start;
            border: 1px solid $border-color;
        }

        &__field-textarea {
            @include textarea;

            width: 100%;
            animation: slide-up 0.4s ease;

            &::placeholder {
                color: $color-gray-blue;
            }
        }

        &__button-wrapper {
            display: flex;
            margin-bottom: 19px;
            justify-content: space-between;
        }

        &__info-icon {
            width: 20px;
            margin-right: 10px;
        }

        &__button {
            @include submit-btn;

            width: 202px;
            color: $color-white;

            @include hover-effect;

            &--buy {
                background: $color-green;
            }

            &--sell {
                background: $color-red;
            }
        }

        &__add-conditions {
            width: 100%;
            display: block;
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 17px;
            letter-spacing: 0.5px;
            margin-bottom: 17px;
            color: $color-gray-blue;
            cursor: pointer;
            text-decoration: underline;
            animation: slide-up 0.4s ease;

            &--no-margin {
                margin-bottom: 0;
            }
        }

        @keyframes slide-up {
            0% {
                opacity: 0;
                transform: translateY(20px);
            }

            100% {
                opacity: 1;
                transform: translateY(0);
            }
        }

        &__gtc-container {
            margin-bottom: 10px;

            .gtc {
                margin-left: 0;

                &__desc {
                    margin: 0;
                    padding: 0;
                }
            }
        }
    }
}
