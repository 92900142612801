@import 'styles/_variables';
@import 'styles/_mixins';

.modal-created-order-screen {
    .created-order-screen {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-bottom: 22px;

        &--top-border {
            border-top: 1px solid $border-color;
        }


        &__icon {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        &__message {
            font-size: 1.5rem;
            line-height: 33px;
            letter-spacing: 0.5px;
            color: $color-dark-blue;
            margin-bottom: 22px;
        }

        &__name {
            font-size: 1rem;
            line-height: 33px;
            letter-spacing: 0.5px;
            color: $color-dark-blue;
        }

        &__orders {
            font-size: 0.875rem;
            letter-spacing: 0.5px;
            font-weight: normal;
            color: $color-gray-blue;
        }

        &__broker-name {
            margin-left: 8px;
        }

        &__button {
            margin-top: 25px;

            @include submit-btn;

            width: 202px;
            color: $color-white;

            @include hover-effect;

            &--close {
                background: $bg-color-button;
            }

            &--indications {
                background: $bg-color-button;
                margin-top: 15px;
            }
        }

        &__description {
            max-width: 300px;
            font-size: 1rem;
            font-weight: 400;
            letter-spacing: 0.5px;
            color: $color-gray-blue;
            text-align: center;
            margin-bottom: 10px;
            margin-top: 10px;
        }
    }
}
