@import 'styles/_variables';
@import 'styles/_mixins';

.modal-error {
    &__message {
        width: 279px;
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 28px;
        align-items: center;
        text-align: center;
        color: $color-gray-blue;
        justify-content: center;
        min-height: 117px;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__button-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__button {
        @include submit-btn;

        padding: 10px 15px;
        margin-top: 5px;
        margin-bottom: 40px;
        width: 180px;
        height: 44px;
        background-color: $color-green;
        color: $color-white;

        @include hover-effect;
    }
}
