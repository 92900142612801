@import 'styles/_variables';
@import 'styles/_mixins';

.modal-confirm-create-order-screen {
    &__header {
        display: flex;
        align-items: flex-start;
    }

    &__back-button {
        @include modal-back-btn;
    }

    .confirm-create-order {
        &__wrapper {
            padding: 0 90px;
        }

        &__product-info-name {
            font-weight: 500;
            font-size: 1.25rem;
            line-height: 33px;
            letter-spacing: 0.5px;
        }

        &__product-info-currency {
            font-size: 0.875rem;
            font-weight: 500;
            margin-left: 2px;
        }

        &__company-name {
            font-weight: 500;
            font-size: 1rem;
            line-height: 33px;
            letter-spacing: 0.5px;
        }

        &__product-wrapper {
            width: 340px;
            background: $bg-color-body;
            border: 1px solid $border-color;
            box-sizing: border-box;
            border-radius: 3px;
            padding: 17px 15px;
            margin-bottom: 25px;
            font-weight: 500;
            font-size: 1.5rem;
            line-height: 30px;
            margin-top: 13px;
        }

        &__product-info {
            display: flex;
            justify-content: space-between;
        }

        &__volume-type {
            font-weight: normal;
            font-size: 0.875rem;
            line-height: 33px;
            letter-spacing: 0.5px;
        }

        &__right-info {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }

        &__product-info-type {
            &--buy {
                color: $color-green;
            }

            &--sell {
                color: $color-red;
            }
        }

        &__product-info-volume {
            text-transform: uppercase;
            display: flex;
            flex-direction: column;
        }

        &__brokerages-list {
            margin-top: 20px;
            display: flex;
            flex-direction: row;
        }

        &__product-info-firm-wrapper {
            display: flex;
            align-items: center;
            margin-bottom: 15px;
        }

        &__product-info-firm {
            font-size: 0.875rem;
            line-height: 33px;
        }

        &__product-info-firm-dot {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $color-brand-blue;
            margin-right: 9px;
        }

        &__label-condition {
            font-weight: 600;
            font-size: 0.875rem;
            line-height: 17px;
            letter-spacing: 0.5px;
            color: $color-gray-blue;
            margin-bottom: 12px;
        }

        &__product-info-note {
            background: $color-white;
            border: 0.5px solid $border-color;
            box-sizing: border-box;
            box-shadow: $input-box-shadow;
            min-height: 44px;
            border-radius: 4px;
            padding: 7px 12px 8px;
            font-size: 14px;
            line-height: 33px;
            letter-spacing: 0.5px;
            color: $color-gray-blue;
            margin-bottom: 21px;
        }

        &__button-confirm {
            width: 100%;
            color: $color-white;
            background-color: $bg-color-button;

            @include submit-btn;
            @include hover-effect;
        }

        &__total-volume {
            font-size: 0.875rem;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            border-top: 1px solid $border-color;
            margin-top: 12px;
            padding-top: 8px;
        }

        &__order-hidden {
            display: inline;
            font-style: italic;
            font-weight: normal;
        }

        &__warning-box {
            margin-bottom: 15px;
            display: flex;
            align-items: flex-start;
        }

        &__warning-text {
            font-size: 0.875rem;
            line-height: 18px;
        }

        &__warning-icon {
            width: 14px;
            display: inline;
            margin-right: 9px;
        }

        &__broker-commission {
            font-size: 0.875rem;
            color: $color-dark-blue;
            font-weight: 400;
            line-height: 16.7px;

            &--margin {
                margin-top: 13px;
            }
        }
    }
}

