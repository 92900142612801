@import 'styles/_variables';
@import 'styles/_mixins';

.number-input {
    position: relative;

    &__field {
        @include input;


        &--warning {
            border-color: $color-yellow !important;
        }
    }

    &__button-wrapper {
        padding: 10px 15px;
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        align-items: center;

        &--small {
            padding: 8px;
        }
    }

    &__button-step {
        height: 8px;
        width: 4px;
        border: none;
        background-color: $color-gray-blue;
        background-repeat: no-repeat;
        mask-image: url(/assets/images/general/chevron.svg);
        mask-repeat: no-repeat;

        @include hover-effect;

        &:focus {
            outline: none;
        }

        &--up {
            transform: rotateX(180deg);
        }
    }
}
