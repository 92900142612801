@import 'styles/_variables';
@import 'styles/_mixins';

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: rgba($color: $color-white, $alpha: 0.5);
    top: 0;
    left: 0;
}
