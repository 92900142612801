@import 'styles/_variables';
@import 'styles/_mixins';

.notifications {
    position: absolute;
    top: 57px;
    left: 0;
    width: 100%;
    height: 100%;
    color: $color-dark-blue;
    z-index: $zIndexBackDrop;

    &--full {
        top: 0;
        position: fixed;
    }

    &__container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: end;
        position: relative;
        margin-right: -22px;

        &--full {
            margin-top: 0;
            margin-right: 0;
        }
    }

    &__pointer {
        width: 0;
        height: 0;
        border-left: 12px solid transparent;
        border-right: 12px solid transparent;
        border-bottom: 16px solid $color-white;
        margin-top: -24px;
        z-index: $zIndexModal;
        margin-right: 28px;
    }

    &__wrapper {
        background-color: $color-white;
        border-radius: 7px;
        position: relative;
        width: 420px;
        z-index: $zIndexModal;
        display: flex;
        flex-direction: column;

        &--full {
            padding: 13px 30px;
            width: 431px;
            height: 100vh;
            border-radius: 0;
        }
    }

    &__header {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        margin: 10px 0 40px 0;
    }

    &__title {
        font-size: 1rem;
        line-height: 21px;
        font-weight: 500;
        text-align: center;
        color: $color-dark-blue;
    }

    &__close-button {
        position: absolute;
        left: 0;
        height: 22px;
        width: 22px;
        border: none;
        transform: scale(1.2);

        @include hover-effect;

        &:focus {
            outline: none;
        }
    }

    &__body {
        padding-top: 14px;
        overflow-y: auto;
        height: 270px;

        &--full {
            // max-height: none;
            height: auto;
        }
    }

    &__entry-wrapper {
        border-bottom: 1px solid $bg-color-body;
        padding: 14px 28px 14px;

        &--full {
            padding: 14px 20px 14px;
        }
    }

    &__scroller-status {
        text-align: center;
        margin-top: 10px;
    }

    &__entry-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-family: $font-family-roboto;
        font-size: 0.875rem;
        letter-spacing: 0.5px;
    }

    &__entry-title {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__entry-icon {
        margin-right: 9px;
        width: 23px;
        height: 23px;
    }

    &__entry-date {
        color: $color-brand-blue;
    }

    &__entry-body {
        margin-top: 2px;
        margin-left: 32px;
        font-family: $font-family-roboto;
        font-size: 0.875rem;
        color: $color-brand-blue;
        letter-spacing: 0.5px;
        line-height: 16px;
    }

    &__view-all {
        font-family: $font-family-roboto;
        font-size: 0.875rem;
        letter-spacing: 0.5px;
        font-weight: 400;
        text-align: center;
        margin: 16px 0;
        cursor: pointer;

        @include hover-effect;
    }

    &__loader {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    @include horizontal-scroller;
}
