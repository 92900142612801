//-------------------------------------//
// MEDIA QUERIES
$media-vlg: 1440px;
$media-lg: 1200px;
$media-md: 992px;
$media-sm: 768px;
$media-xs: 480px;

//-------------------------------------//
// COLORS
// Projects color palette
// TODO_REPLACE(projects own color palette)
$color-brand-blue: #8eabda;
$color-white: #fff;
$color-black: #000;
$color-dark-blue: #333f4e;
$color-link-blue: #4903fc;
$color-dark-gray: #bababa;
$color-low-blue: #97b1db;
$color-gray-blue: #8298af;
$color-light-blue: #ecf3fe;
$color-green: #54813c;
$color-light-green: #219653;
$color-green-soft: #46CE80;
$color-red: #b80009;
$color-light-red: #f0cfd1;
$color-yellow: #f6ae28;
$color-red-soft: #fff9f9;
$color-lightgray: #e1e8ee;
$color-link: #f3f5f9;
$color-hover: $color-dark-blue;
$color-negotiation: #ea8b1f;
$bg-color-modal: white;
$bg-color-button: #8eabda;
$bg-color-body: #f3f5f9;
$color-gray-soft: #f9fbff;
$color-gray-ultra-soft: #f2f4f8;
$border-color: #d6dce3;
$border-color-light: #dadde9;
$tooltip-color: #69717d;
$box-shadow-color: rgba(166, 173, 198, 0.1);
$box-shadow-color-lighter: rgba(95, 95, 95, 0.24);
$box-shadow-color-darker: #d6d9e3;
$box-shadow-menu: rgba(80, 81, 98, 0.1);
$box-shadow-input: rgba(214, 217, 227, 0.6);
$box-shadow-title: #a6adc61a;
$post-api-box: #edf9f4;
$post-api-title: #5bd09a;
$get-api-box: #f0f6fd;
$get-api-title: #6fb5fd;

$color-weak-password: #b80009;
$color-medium-password: #ea8b1f;
$color-strong-password: #54813c;
$color-light-gray: #a6adc64d;
//-------------------------------------//
// FONTS
// TODO_REPLACE(projects font families)
// $font-family-poppins: 'Poppins', Arial, Sans-Serif;
$font-family-roboto: 'Roboto', sans-serif;

$font-family-body: 'sfPro';
$font-color-body: #f3f5f9;
$font-size-body: 14px;

$font-light: 300;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;
$font-bolder: 900;

//-------------------------------------//
// SPACING
$spacing: 20px;
$spacing-half: $spacing / 2;
$spacing-double: $spacing * 2;
$spacing-third: $spacing * 3;

//-------------------------------------//
// Z-INDEXING
// $zIndex-AppHeaderNav: 1000;
// $zIndex-AppSideNav: 1010;
// ...

//-------------------------------------//
// Global Variables

// Radius
// TODO_REPLACE(projects radiuses)
$border-radius-button: 4px;
$border-radius-small: 3px;
$border-radius-medium: 6px;
$border-radius-large: 9px;

// Box Shadow
// TODO_REPLACE(projects shadows)
$box-shadow-light: 0 1px 3px rgba(#000, 0.12), 0 1px 2px rgba(#000, 0.24);
$box-shadow-medium: 0 3px 6px rgba(#000, 0.16), 0 3px 6px rgba(#000, 0.23);
$box-shadow-heavy: 0 10px 20px rgba(#000, 0.19), 0 6px 6px rgba(#000, 0.23);

$input-box-shadow: inset 0 0 3px rgba(142, 171, 218, 0.5);
$input-border: 0.5px solid $border-color;
$switch-box-shadow: 0 0 2px rgba(0, 0, 0, 0.14), 0 2px 2px rgba(0, 0, 0, 0.12), 0 1px 3px rgba(0, 0, 0, 0.2);

//-------------------------------------//
// Component Variables
// TODO_REPLACE(project component styling)
// App Header Nav
// $AppHeaderNav-height: 60px;
// $AppHeaderNav-z-index: $zIndex-AppHeaderNav;

// App Side Nav
// $AppSideNav-width: 240px;
// $AppSideNav-z-index: 160ms;

// ActivityIndicator
// $ActivityIndicator-stroke: $color-brand-blue;

// CheckBox
$checkbox-border: 1px solid #8ca0b4;
$checkbox-color: $color-gray-blue;
// $checkbox-checked-background-color: #AE77EA;

// RadioBox
// $RadioBox-border: 2px solid #E4F0FC;
// $RadioBox-hover-border: 7px solid #e9dcf7;
// $RadioBox-hover-background-color: rgba(#AE77EA, 0.5);
// $RadioBox-checked-border: 7px solid #e9dcf7;
// $RadioBox-checked-background-color: #AE77EA;

// ...
$zIndexBackDrop: 1500;
$zIndexModal: $zIndexBackDrop + 100;
$required: #c53138;
$form-desc: #596b7f;
$checkbox-bg: #97b1db;
$step-bar-circle: #736fd3;
$step-bar-line: #645fce;
$lock-bg: rgba(196, 196, 196, 0.35);
$new-device-desc: #7d858e;
$box-error-border: #d8454c;
$box-error-shadow: #e20510;
$back-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
$input-color: #4d4d4d;
$material-input-color: #212121;
$material-input-error: #b80009;
