@import 'styles/_variables';
@import 'styles/_mixins';

.header-nudge {
    display: inline-flex;

    &__indicator {
        position: absolute;
        top: 25px;
        background-color: $color-red;
        width: 6px;
        height: 6px;
        border-radius: 50%;

        &--pulsing {
            @include pulsing(header-nudge-pulse, 20%, 30px, rgba($color-red, 0.1));

            animation: header-nudge-pulse 2s infinite;
        }
    }
}
