@import 'styles/_variables';
@import 'styles/_mixins';

.react-lock-screen-ui {
    background: $lock-bg;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 9999999999999;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */

    /* Chrome, Safari, Edge, Opera */
    -webkit-appearance: none;
    margin: 0;
    -moz-appearance: textfield;

    &__title {
        font-weight: normal;
        font-size: 0.9375rem;
        align-items: center;
        color: $color-gray-blue;
        margin: 0 25px 10px;
        display: inline-block;
    }

    &__err {
        color: $color-red;
        margin-left: 25px;
    }
}

.react-lock-screen-ui-box {
    background: $color-white;
    border-radius: 4px;
    width: 420px;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0 50px 50px 0;
    min-height: 155px;
    padding: 25px;
}

.react-lock-screen-ui-input {
    border-width: 0 0 3px 0;
    border-color: $border-color;
    width: 35px;
    margin: 0 25px 0 0;
    color: $color-dark-blue;
    text-align: center;

    &:focus {
        outline: none;
    }

    &:first-child {
        margin-left: 25px;
    }
}

.react-lock-screen-ui-btn {
    background: $color-brand-blue;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    height: 48px;
    width: 83px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &__btn {
        background: none;
        border: none;
        cursor: pointer;
    }
}

.react-lock-screen-ui-btn:focus-visible {
    outline: none !important;
}

.error-ui-box {
    border: 2px solid $box-error-border;
    box-shadow: 3px 2px 8px $box-error-shadow;
}

.shakeIt {
    animation: shake 0.5s;
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
        transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
        transform: translate(-3px, 0) rotate(1deg);
    }

    30% {
        transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
        transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
        transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
        transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
        transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
        transform: translate(1px, -2px) rotate(-1deg);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}
