@import 'styles/_variables';
@import 'styles/_mixins';

.appLock {
    position: fixed;
    left: 0;
    top: 0;
    text-align: center;
    z-index: 999999;
    background: $lock-bg;
    width: 100%;
    height: 100%;
    display: flex;

    &__test {
        display: block;
        font-weight: normal;
        font-size: 0.9375rem;
        align-items: center;
        color: $color-gray-blue;
    }

    .ActivityIndicator {
        margin: 0 auto;
    }
}
